import Vue from "vue";
import Vuex from "vuex";
import peligrosYRiesgos from "@/views/Helexium/PeligrosYRiesgos/store.js";
import inspeccionVehiculo from "@/views/Helexium/InspeccionVehiculo/store.js";
import moment from "moment-timezone";
import helexium from "./Todo/helexium";
import storeComponents from "./storeComponents.js";
import formsPesvFase2 from "@/views/Helexium/PESV/pesv_fase2_components/store.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    APIAportes: `https://nominaelectronicaapipruebas.aportesenlinea.com/Api`,
    modalChatIA: false,
    userLoggedIn: null,
    appName: "ProX",
    logo: require("../assets/images/logo.png"),
    darklogo: require("../assets/images/logo-white.png"),
    dark: false,
    namespaced: true,
    PersonaAct: null,
    isLoading: false,
    // notif_pendientes modalHome
    pendientesPlanes: 0,
    pendientesOnboardings: 0,
    pendientesMiOnboarding: 0,
    user: {
      name: "Barry Tech",
      image: require("../assets/images/user/1.jpg"),
      image2: require("../assets/images/layouts/layout-3/user-1.jpg"),
      image3: require("../assets/images/user/02.jpg"),
    },
    //helexium
    url: "https://helex.mecasite.com/api/",
    // url: "http://localhost:5105/api/",
    // hl_url: "https://localhost:44388/api/", //ok
    //  hl_url: "http://127.0.0.1:44388/api/", //ok
    // hl_url: "http://127.0.0.1:44388/Api/",
    hl_url: "https://bupewebprodapi.azurewebsites.net/api/", //ok
    // hl_url: "https://bupeapidevelopment.azurewebsites.net/api/", //ok
    // hl_url: "http://devbackend.helexiumfiles.com/api/", //ok
    modalShow: false,
    group: 0,
    nuevoChat: 0,
    // coins canjes
    totalCoins: 0,
    totalCanjes: 0,
    configCoinsCanjes: {
      id: null,
      empresaId: null,
      areaIds: [],
      avatar: 0,
      onboarding: 0,
      curriculum: 0,
      tareaBasica: 0,
      tareaMedia: 0,
      tareaAlta: 0,
      rango_50_59: 0,
      rango_60_74: 0,
      rango_75_84: 0,
      rango_85_94: 0,
      rango_95_100: 0,
    },
    gananciasCoins: {
      avatar: false,
      onboarding: false,
      curriculum: false,
      tasks: {
        basico: {
          completed: 0,
          pendients: 0,
        },
        medio: {
          completed: 0,
          pendients: 0,
        },
        alto: {
          completed: 0,
          pendients: 0,
        },
      },
      evaluaciones: {
        r_50_59: {
          cant: 0,
          ganTotal: 0,
        },
        r_60_74: {
          cant: 0,
          ganTotal: 0,
        },
        r_75_84: {
          cant: 0,
          ganTotal: 0,
        },
        r_85_94: {
          cant: 0,
          ganTotal: 0,
        },
        r_95_100: {
          cant: 0,
          ganTotal: 0,
        },
      },
    },
  },
  mutations: {
    showChatIA(storeState, modalState) {
      storeState.modalChatIA = modalState;
    },

    setUserLoggedIn(state, user) {
      state.userLoggedIn = user;
    },
    // coins canjes
    setTotalCoins: (state, newValue) => {
      state.totalCoins = newValue;
    },
    // total canjes
    setTotalCanjes: (state, newValue) => {
      state.totalCanjes = newValue;
    },
    // avatar
    setIsAvatar: (state, newValue) => {
      state.gananciasCoins.avatar = newValue;
    },
    // onboarding
    setIsOnboarding: (state, newValue) => {
      state.gananciasCoins.onboarding = newValue;
    },
    // curriculum
    setIsCurriculum: (state, newValue) => {
      state.gananciasCoins.curriculum = newValue;
    },
    // tasks
    setGananciasCoinsTasks: (state, newValue) => {
      state.gananciasCoins.tasks = newValue;
    },
    // rango evaluaciones
    setGananciasCoinsRangoEvaluaciones: (state, newValue) => {
      state.gananciasCoins.evaluaciones = newValue;
    },
    // config coins
    setConfigCoinsCanjes: (state, newValue) => {
      state.configCoinsCanjes = newValue;
    },
    // modalHome setters
    refrescarChat: (state, data) => {
      console.log(data);
      state.nuevoChat++;
    },
    setPendientesPlanes: (state, newValue) => {
      state.pendientesPlanes = newValue;
    },
    setPendientesOnboardings: (state, newValue) => {
      state.pendientesOnboardings = newValue;
    },
    setPendientesMiOnboarding: (state, newValue) => {
      state.pendientesMiOnboarding = newValue;
    },

    groupRefresh(state, data) {
      console.log("actualiza grupo en store.....", data);
      localStorage.setItem("group", data);
      state.group = data;
    },
    modalShowRefresh(state, data) {
      console.log("cambiando estado modalshow desde mutación", data);
      state.modalShow = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
  },
  actions: {
    wrapperSetUserLoggedIn({ commit }, user) {
      // getters
      //   .fetchGet({
      //     path: "Persona/GetPersonaById/" + user.id,
      //   })
      //   .then((res) => {
      //     return res.json();
      //   })
      //   .then((nuevoUsuario) => {
      //     commit("setUserLoggedIn", nuevoUsuario);
      //   });
      commit("setUserLoggedIn", user);
    },

    async onFileSelectedHistoriaL(context, { filename, type, userId, file }) {
      try {
        let formData = new FormData();
        formData.append("name", filename);
        formData.append("file", file);
        let url =
          context.state.url +
          "uploadFiles/" +
          filename +
          "/" +
          type +
          "/" +
          userId;
        const options = {
          method: "POST",
          "Content-Type": "application/json",
          body: formData,
        };
        let req = await fetch(url, options);
        if (req.ok) {
          return true;
        }
      } catch (error) {
        // console.log("error in onfileselected", error);
        return false;
      }
    },
    async onFileSelectedSst(context, { filename, file, data }) {
      try {
        //uploadFilespesv/:empresa/:sucursal/:pesvevaluacionid/:fase/:paso
        let formData = new FormData();
        formData.append("name", filename);
        formData.append("file", file);
        let url =
          context.state.url +
          "uploadFilesSst/" +
          data.empresa +
          "/" +
          data.sucursal +
          "/" +
          data.pesvevaluacionid +
          "/" +
          data.fase +
          "/" +
          data.paso;
        const options = {
          method: "POST",
          "Content-Type": "application/json",
          body: formData,
        };
        let req = await fetch(url, options);
        if (req.ok) {
          return true;
        }
      } catch (error) {
        //console.log("error in onfileselected", error);
        return false;
      }
    },
    async onDropFile({ state }, { filename, file, url }) {
      try {
        let formData = new FormData();
        formData.append("file", file, filename);

        url = state.url + "customUploadFile/" + url;
        const options = {
          method: "POST",
          "Content-Type": "application/json",
          body: formData,
        };
        let req = await fetch(url, options);
        if (req.ok) {
          return true;
        }
      } catch (error) {
        console.log("error in onfileselected", error);
        return false;
      }
    },
    async onFileCustom(context, { filename, file, url }) {
      try {
        //uploadFilespesv/:empresa/:sucursal/:pesvevaluacionid/:fase/:paso
        let formData = new FormData();
        formData.append("name", filename);
        formData.append("file", file);
        // let url =
        //   ".|site1|users|pesv|evaluaciones|" +
        //   data.empresa +
        //   "|" +
        //   data.sucursal +
        //   "|" +
        //   data.pesvevaluacionid +
        //   "|" +
        //   data.fase +
        //   "|" +
        //   data.paso;
        url = context.state.url + "customUploadFile/" + url;
        const options = {
          method: "POST",
          "Content-Type": "application/json",
          body: formData,
        };
        let req = await fetch(url, options);
        if (req.ok) {
          return true;
        }
      } catch (error) {
        console.log("error in onfileselected", error);
        return false;
      }
    },
    async onFileSelected(context, { filename, file, data }) {
      try {
        //uploadFilespesv/:empresa/:sucursal/:pesvevaluacionid/:fase/:paso
        let formData = new FormData();
        formData.append("name", filename);
        formData.append("file", file);
        let url =
          // context.state.url +
          // "uploadFilespesv/" +
          ".|site1|users|pesv|evaluaciones|" +
          data.empresa +
          "|" +
          data.sucursal +
          "|" +
          data.pesvevaluacionid +
          "|" +
          data.fase +
          "|" +
          data.paso;

        // url = context.state.url+"customUploadFile/" + "./site1/users/sst/evaluaciones/" + req.params.empresa + "/" + req.params.sucursal + "/" + req.params.sstevaluacionid + "/" + req.params.fase + "/" + req.params.paso;
        //const pathFile= ".|site1|users|testabr|123|"+data.empresa
        url = context.state.url + "customUploadFile/" + url;
        const options = {
          method: "POST",
          "Content-Type": "application/json",
          body: formData,
        };
        let req = await fetch(url, options);
        if (req.ok) {
          return true;
        }
      } catch (error) {
        //console.log("error in onfileselected", error);
        return false;
      }
    },
    startSession() {
      localStorage.setItem(
        "session",
        JSON.stringify({ session_id: new Date() })
      );
      ////console.log(localStorage.getItem('session'))
      this.commit("updateLogged", true);
    },
    closeSession() {
      localStorage.setItem("session", JSON.stringify({}));
      this.commit("updateLogged", false);
    },
    async existsSession() {
      let result;
      try {
        let res = JSON.parse(localStorage.getItem("session")) || {};
        ////console.log('res', res)
        if (res.session_id) {
          result = true;
        } else {
          result = false;
        }
        this.commit("updateLogged", result);
      } catch (error) {
        result = false;
        this.commit("updateLogged", result);
      }
      return await result;
    },
    async login(context, { data }) {
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.url + "users/autenticate", options);
        let res = await req.json();
        this.commit("setToken", res.token);

        localStorage.setItem("tk", res.token);
        localStorage.setItem("user", JSON.stringify(res.data));
        // VOLVER
        // let res = await this.$store.getters.fetchGet( {
        //   path: "Persona/GetPersonaById/" + this.PersonaId,
        // });
        // this.Persona = await res.json();
        if (res.error) {
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          alert(res.error);
          return;
        } else {
          ////console.log('login')
          return res;
        }
      } catch (error) {
        console.error("error", error);
        return 0;
      }
    },
    async gethlw(context, { path }) {
      try {
        // let token = localStorage.getItem('tk')
        const options = {
          method: "GET",
          headers: {
            // 'access-token': token,
            // 'Content-Type': 'application/json',
          },
        };
        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error", error);
        return error;
      }
    },
    async getw(context, { path }) {
      try {
        // let token = localStorage.getItem('tk')
        const options = {
          method: "GET",
          headers: {
            // 'access-token': token,
            // 'Content-Type': 'application/json',
          },
        };
        let req = await fetch(context.state.url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error", error);
        return error;
      }
    },
    async get(context, { path }) {
      try {
        // let token = localStorage.getItem("tk");
        const options = {
          method: "GET",
          headers: {
            // "access-token": token,
            Authorization: `Bearer ${localStorage.getItem("tk")}`,
            "Content-Type": "application/json",
          },
        };
        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error", error);
        return error;
      }
    },

    async post(context, { path, data }) {
      try {
        // let token = localStorage.getItem('tk')
        const options = {
          method: "POST",
          headers: {
            // 'access-token': token,
            Authorization: `Bearer ${localStorage.getItem("tk")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async posthlw(context, { path, data }) {
      try {
        // let token = localStorage.getItem('tk')
        const options = {
          method: "POST",
          headers: {
            // 'access-token': token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async postw(context, { path, data }) {
      try {
        // let token = localStorage.getItem('tk')
        const options = {
          method: "POST",
          headers: {
            // 'access-token': token,
            // Authorization: `Bearer ${localStorage.getItem("tk")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async patch(context, { path, data }) {
      try {
        const options = {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tk")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };

        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();

        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async put(context, { path, data }) {
      try {
        // let token = localStorage.getItem('tk')
        const options = {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tk")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async delete(context, { path }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "DELETE",
          headers: {
            "access-token": token,
            "Content-Type": "application/json",
          },
        };
        let req = await fetch(context.state.url + path, options);
        ////console.log(req)
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (req.status != 200) {
          alert(req.error);
        } else {
          return req;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
  },
  getters: {
    // config coins
    getConfigCoinsCanjes: (state) => {
      return state.configCoinsCanjes;
    },
    // total canjes
    getTotalCanjes: (state) => {
      return state.totalCanjes;
    },
    // coins canjes
    getTotalCoins: (state) => {
      return state.totalCoins;
    },
    // avatar
    getIsAvatar: (state) => {
      return state.gananciasCoins.avatar;
    },
    // onboarding
    getIsOnboarding: (state) => {
      return state.gananciasCoins.onboarding;
    },
    // curriculum
    getIsCurriculum: (state) => {
      return state.gananciasCoins.curriculum;
    },
    // task
    getGananciasCoinsTasks: (state) => {
      return state.gananciasCoins.tasks;
    },
    // rango evaluaciones
    getGananciasCoinsRangoEvaluaciones: (state) => {
      return state.gananciasCoins.evaluaciones;
    },
    // modalHome getters 👇
    getPendientesPlanes: (state) => {
      return state.pendientesPlanes;
    },
    getPendientesOnboardings: (state) => {
      return state.pendientesOnboardings;
    },
    getPendientesMiOnboarding: (state) => {
      return state.pendientesMiOnboarding;
    },
    // modalHome getters ☝️

    getLoading: (state) => {
      return state.isLoading;
    },
    appName: (state) => {
      return state.appName;
    },
    logo: (state) => {
      return state.logo;
    },
    darklogo: (state) => {
      return state.darklogo;
    },
    image1: (state) => {
      return state.user.image;
    },
    name: (state) => {
      return state.user.name;
    },
    image2: (state) => {
      return state.user.image2;
    },
    image3: (state) => {
      return state.user.image3;
    },
    getDateNow() {
      return () => {
        //año-mes-diaT00:00:00 // El backend y la base de datos funciona de este modo
        let dia =
          new Date().getDate() < 10
            ? "0" + new Date().getDate()
            : new Date().getDate();
        let mes =
          new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1;

        return [new Date().getFullYear(), mes, dia].join("-") + "T00:00:00";
      };
    },
    toFormatDate() {
      return (backDate) => {
        //dia-mes-año

        let date = new Date(backDate);
        return [date.getDate(), date.getMonth() + 1, date.getFullYear()].join(
          "/"
        );
      };
    },
    formatDateTime() {
      return (backDate) => {
        let date = new Date(backDate);
        let horas = date.getHours();
        let minutos = date.getMinutes();
        horas = horas < 10 ? "0" + horas : horas;
        minutos = minutos < 10 ? "0" + minutos : minutos;

        let onlyDate = [
          date.getDate(),
          date.getMonth() + 1,
          date.getFullYear(),
        ].join("/");
        let onlyTime = horas + ":" + minutos;
        return onlyDate + " " + onlyTime;
      };
    },
    dateFormatInput() {
      return (backDate) => {
        let date = new Date(backDate);
        let dia = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let mes =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;

        return [date.getFullYear(), mes, dia].join("-");
      };
    },
    useMoment() {
      return (date) => {
        //Regresa el formato fecha hora
        return moment(date).format("YYYY/MM/DD HH:mm");
      };
    },
    useMomentToLocaleDateString() {
      return (date) => {
        return moment(date).format("YYYY-MM-DD");
      };
    },
    useMomentInverseToInputDate() {
      return (date) => {
        //Regresa el formato fecha hora
        return moment(date).format("YYYY-MM-DD");
      };
    },
    fetchGet(state) {
      return async ({ path, metaHeaders = {} }) => {
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
        };

        let response = await fetch(state.hl_url + path, {
          method: "GET",
          headers: headersList,
          ...metaHeaders,
        });

        return response;
      };
    },
    fetchQuery(state) {
      return async ({ path, query, metaHeaders = {} }) => {
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
          "Content-Type": "application/json",
        };

        let bodyContent = JSON.stringify({ query });

        let response = await fetch(state.hl_url + path, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
          ...metaHeaders,
        });

        return response;
      };
    },
    fetchPost(state) {
      return async ({ path, data, metaHeaders = {} }) => {
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
          "Content-Type": "application/json",
        };

        let bodyContent = JSON.stringify(data);

        let response = await fetch(state.hl_url + path, {
          method: "POST",
          body: bodyContent,
          headers: headersList,
          ...metaHeaders,
        });

        return response;
      };
    },

    //fetchPut
    fetchPut(state) {
      return async ({ path, data, metaHeaders = {} }) => {
        let bodyContent = JSON.stringify(data);

        // const credenciales = JSON.parse(localStorage.getItem("credenciales"));

        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
          "Content-Type": "application/json",
          ...metaHeaders,
        };

        let response = fetch(state.hl_url + path, {
          method: "PUT",
          body: bodyContent,
          headers: headersList,
        });
        return response;
      };
    },

    //fetchDelete
    fetchDelete(state) {
      return async ({ path, metaHeaders = {} }) => {
        let headersList = {
          Accept: "*/*",
          Authorization: `Bearer ${localStorage.getItem("tk")}`,
        };

        let response = await fetch(state.hl_url + path, {
          method: "DELETE",
          headers: headersList,
          ...metaHeaders,
        });

        return response;
      };
    },

    userLoggedIn(state) {
      // return state.userLoggedIn
      // return JSON.parse(localStorage.setPersonaAct);
      return state.userLoggedIn;
    },
    tiempoTranscurrido() {
      return (date, backend = false) => {
        // backend true si date es de tipo DateTime.UtcNow
        var nombresMeses = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        let now;
        if (backend) {
          date = date.split(".")[0];
          now = new Date().toISOString().split(".")[0];
        } else {
          now = new Date();
        }

        date = new Date(date);
        now = new Date(now);

        const diff = Math.abs(now - date);
        const seconds = diff / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;

        const minutesRelative =
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

        if (seconds < 60) {
          return "Hace unos segundos";
        } else if (minutes < 60) {
          return `Hace ${Math.floor(minutes)} minutos`;
        } else if (hours < 24) {
          return `Hace ${Math.floor(hours)} horas`;
        } else if (days <= 1) {
          return `Ayer a las ${date.getHours()}:${date.getMinutes()}`;
        } else if (days > 1) {
          return `${date.getDate()} de ${nombresMeses[date.getMonth()]
            } a las ${date.getHours()}:${minutesRelative}`;
        } else if (days > 365) {
          return `El ${date.getDate()} de ${nombresMeses[date.getMonth()]
            } de ${date.getFullYear()} a las ${date.getHours()}:${date.getMinutes()}`;
        }
      };
    },
    getTokenAportes(state, getters) {
      return async () => {
        const tkAportes = JSON.parse(localStorage.getItem("tkAportes"));
        if (tkAportes && new Date() - new Date(tkAportes.fecha) < 3600000) {
          return tkAportes.token;
        } else {
          try {
            const { empresa } = getters.userLoggedIn;
            const user = empresa.userAportesEnLinea;
            const password = empresa.passwordAportesEnLinea;

            const response = await fetch(
              `${state.APIAportes}/NominaElectronica/Autenticacion`,
              {
                method: "POST",
                headers: {
                  accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  user,
                  password,
                  ambiente: 2, // Este campo no es relevante. pero obligatorio para la petición
                }),
              }
            );

            const { token } = await response.json();
            if (token) {
              localStorage.setItem(
                "tkAportes",
                JSON.stringify({ token, fecha: new Date() })
              );
            } else {
              throw new Error(
                "Error de autenticación aportes en linea. vuelva a intentar"
              );
            }
            console.log("interno", token);
            return token;
          } catch (error) {
            console.log("error", error);
            return getters.getTokenAportes();
          }
        }
      };
    },
  },

  modules: {
    peligrosYRiesgos,
    inspeccionVehiculo,
    helexium,
    ...storeComponents,
    formsPesvFase2,
  },
});
