import "@babel/polyfill";
import "mutationobserver-shim";
import Raphael from "raphael/raphael";
global.Raphael = Raphael;
import Vue from "vue";
import "./plugins";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./directives";


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import IdleVue from "idle-vue";

import VueToggles from "vue-toggles";

import VueSocketIO from "vue-socket.io";


import 'css-peeps/css-peeps.css';
// import SocketIO from "socket.io-client";

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: 'https://helex.mecasite.com:5105',
//   vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_'
//   },
//   // options: { path: "/my-app/" } //Optional options
// }))
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import HelexSelect from "@/components/global/HelexSelect";
Vue.component("HelexSelect", HelexSelect);

const options = { transports: ["polling"] };

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: "https://helex.mecasite.com",
    options: options,
  })
);

Vue.component("VueToggles", VueToggles);

Vue.config.productionTip = false;
import loading from "vuejs-loading-screen";

Vue.use(loading, {
  bg: "#3a5c91ad",
  slot: `
    <div class="px-5 py-3  rounded">
      <h3 class="text-3xl text-white"><i class="fa fa-spinner fa-spin"></i> ${"Espere..."}</h3>
    </div>
  `,
});

import VueMoment from "vue-moment";
import moment from "moment-timezone";

Vue.use(VueMoment, {
  moment,
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1500000, // -- 15 minutos - para desarrollo
  // idleTime: 500000 // 5 minutos - dejar este por defecto
});

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

new Vue({
  onIdle() {
    console.log("zzzzzz");
    this.messageStr = "ZZZ";
    this.$router.replace("/");
    // localStorage.removeItem('setPersonaAct')
  },
  onActive() {
    this.messageStr = "Hello";
    console.log("Hola!");
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
export var eventBus = new Vue();

import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  colors: {
    // primary: "#186bdd",
    // primary: "#186bdd", claro odoo
    // primary: "#feeb64",
    // danger: "rgb(248, 116, 143)",
    // success: "rgb(154, 227, 152)",
    // warning: "#c99eee",
    // dark: "rgb(190, 202, 255)",
    // transparent: "transparent",
    primary: "#6272ff",
    danger: "#d43862",
    success: "#2aaffc",
    warning: "#b33af7",
    dark: "#02225b",
    transparent: "transparent",
  },
});
