const documentacion = [
  {
    title: "Tipo de Inspeccion",
    name: "tipoInspeccion",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "preoperativa", text: "Preoperativa" },
      { value: "rutinaria", text: "Rutinario" },
      { value: "por entrega", text: "Por entrega" },
    ],
  },
  {
    title: "Tarjeta de propiedad",
    name: "tarjetaPropiedad",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
    ],
  },
  {
    title: "Seguro obligatorio", // ?soat
    name: "seguroObligatorio",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
    ],
  },
  {
    title: "Revisión tecnomecánica",
    name: "revisionTecnomecanica",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
    ],
  },
  {
    title: "Póliza contractual",
    name: "polizaContractual",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
    ],
  },
  {
    title: "Observaciones",
    name: "observacionesDocumento",
    type: "textarea",
    value: null,
    span: 3,
  },
];

const OrdenYAseo = [
  {
    title: "Aseo general (externa e interna del vehiculo)",
    name: "aseoGeneral",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "malo", text: "Malo" },
      { value: "regular", text: "Regular" },
      { value: "bueno", text: "Bueno" },
      { value: "excelente", text: "Excelente" },
    ],
  },
  {
    title: "Acceso y pisos sin obstaculos",
    name: "accesoPisosSinObstaculos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "malo", text: "Malo" },
      { value: "regular", text: "Regular" },
      { value: "bueno", text: "Bueno" },
      { value: "excelente", text: "Excelente" },
    ],
  },
  {
    title: "Almacenamiento de herramienta",
    name: "almacenamientoHerramienta",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "malo", text: "Malo" },
      { value: "regular", text: "Regular" },
      { value: "bueno", text: "Bueno" },
      { value: "excelente", text: "Excelente" },
    ],
  },
  {
    title: "Almacenamiento de repuestos",
    name: "almacenamientoRepuestos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "malo", text: "Malo" },
      { value: "regular", text: "Regular" },
      { value: "bueno", text: "Bueno" },
      { value: "excelente", text: "Excelente" },
    ],
  },
  {
    title: "Cabina ordenada sin objetos en el suelo",
    name: "cabinaOrdenadaSinObjetosSuelo",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "malo", text: "Malo" },
      { value: "regular", text: "Regular" },
      { value: "bueno", text: "Bueno" },
      { value: "excelente", text: "Excelente" },
    ],
  },
  {
    title: "Observaciones",
    name: "observacionesOrdenAseo",
    type: "textarea",
    value: null,
    span: 3,
  },
];

const carroceria = [
  {
    title: "Llanta de repuesto",
    name: "llantaRepuesto",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Estado de Llantas (Presión - Labrado)",
    name: "estadoLlantas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Rines",
    name: "rines",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Bomper trasero",
    name: "bomperTrasero",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Bomper delantero",
    name: "bomperDelantero",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Stop",
    name: "stop",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Placa",
    name: "placa",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Guarda polvos",
    name: "guardaPolvos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Emblemas",
    name: "emblemas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Manijas",
    name: "manijas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Barra antivuelco",
    name: "barraAntivuelco",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Cubre platón",
    name: "cubrePlaton",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Vidrios",
    name: "vidrios",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Espejos",
    name: "espejos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Lame vidrio",
    name: "lameVidrio",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Cocuyos",
    name: "cocuyos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Plumillas",
    name: "plumillas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Mionas",
    name: "mionas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Antena",
    name: "antena",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Direccionales",
    name: "direccionales",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Luces",
    name: "luces",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Ganchos de remolque",
    name: "ganchosRemolque",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Llavero",
    name: "llavero",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Observaciones",
    name: "observacionesCarroceria",
    type: "textarea",
    value: null,
    span: 3,
  },
];

const cabina = [
  {
    title: "Silla Conductor",
    name: "sillaConductor",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Silla Copiloto",
    name: "sillaCopiloto",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Silla Pasajeros",
    name: "sillaPasajeros",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Forros",
    name: "forros",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Volante",
    name: "volante",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Tablero de instrumentos",
    name: "tableroInstrumentos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Espejo retrovisor",
    name: "espejoRetrovisor",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Reloj",
    name: "reloj",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Funcion Pito (normal y de reversa)",
    name: "funcionPito",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Tapetes",
    name: "tapetes",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Manija freno de mano",
    name: "manijaFrenoDeMano",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Botones de a/c",
    name: "botonesAc",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Radio",
    name: "radio",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Control Radio",
    name: "controlRadio",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Parlantes",
    name: "parlantes",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Elevavidrios",
    name: "elevavidrios",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Switch espejos",
    name: "switchEspejos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Switch Luces",
    name: "switchLuces",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Viceras",
    name: "viceras",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Cinturones de seguridad",
    name: "cinturonesSeguridad",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Manijas internas",
    name: "manijasInternas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Encendedor",
    name: "encendedor",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
      { value: "noAplica", text: "No aplica" },
    ],
  },

  {
    title: "Cenicero",
    name: "cenicero",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "si", text: "Si" },
      { value: "no", text: "No" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Observaciones",
    name: "observacionesCabina",
    type: "textarea",
    value: null,
    span: 3,
  },
];

const motor = [
  {
    title: "Bateria",
    name: "bateria",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Filtro trampa",
    name: "filtroTrampa",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Posicion de las correas",
    name: "posicionCorreas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Bayoneta",
    name: "bayoneta",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Tapas (aceite, hidraulico, radiador)",
    name: "tapas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Conexión de cables y mangueras",
    name: "conexionesCables",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Nivel de aceite, refrigerante y liquido de frenos",
    name: "niveles",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Kilometraje (km)",
    name: "kilometraje",
    type: "number",
    value: 0,
  },
  {
    title: "Observaciones",
    name: "observacionesMotor",
    type: "textarea",
    value: null,
    span: 3,
  },
];

const herramientas = [
  {
    title: "Gato",
    name: "gato",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Palanca Gato",
    name: "palancaGato",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Conos",
    name: "conos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Hombre solo",
    name: "hombreSolo",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Alicate",
    name: "alicate",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Llaves boca fija",
    name: "llavesBocaFija",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Cables de inicio",
    name: "cablesInicio",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Botiquin reglamentario",
    name: "botiquin",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Extintor",
    name: "extintor",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Llave alemana",
    name: "llaveAlemana",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Llave de perno",
    name: "llavePerno",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Tacos Plasticos",
    name: "tacosPlasticos",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Calibrador de presion",
    name: "calibradorPresion",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Lampara o linterna",
    name: "lamparaLinterna",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Destornillador mixto",
    name: "destornilladorMixto",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Llaves bristol",
    name: "llavesBristol",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Manila 12m #12",
    name: "manila12m",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Otras",
    name: "otras",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Observaciones",
    name: "observacionesHerramientas",
    type: "textarea",
    value: null,
    span: 3,
  },
];

const kitAmbiental = [
  {
    title: "Pala Antichispas y Escobilla",
    name: "palaAntichispasEscobilla",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Tela Oleofilica o Paños Absorbentes",
    name: "telaOleofilicaPanosAbsorbentes",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Bolsas Rojas",
    name: "bolsasRojas",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Cinta de Seguridad (Amarillo/Negro)",
    name: "cintaDeSeguridad",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Guantes de Nitrilo",
    name: "guantesDeNitrilo",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Protección Respiratoria",
    name: "proteccionRespiratoria",
    type: "select",
    value: null,
    options: [
      { value: null, text: "Seleccione" },
      { value: "excelente", text: "Excelente" },
      { value: "bueno", text: "Bueno" },
      { value: "regular", text: "Regular" },
      { value: "malo", text: "Malo" },
      { value: "noAplica", text: "No aplica" },
    ],
  },
  {
    title: "Observaciones",
    name: "observacionesElementosSeguridad",
    type: "textarea",
    value: null,
    span: 3,
  },
];

export default {
  documentacion,
  OrdenYAseo,
  carroceria,
  cabina,
  motor,
  herramientas,
  kitAmbiental,
};
