export const reclutamientoYSeleccionState = {
  nuevaPostulacionDatosPersonales: [
    {
      name: 'nombres',
      label: 'Nombres',
      validations: 'required',
    },
    {
      name: 'apellidos',
      label: 'Apellidos',
      validations: 'required',

    },
    {
      name: 'correo',
      label: 'Correo electrónico',
      type: 'email',
      validations: 'required',
    },
    {
      name: 'dateBorn',
      label: 'Fecha de nacimiento',
      type: 'date',
      validations: 'required',
    },
    {
      name: 'tipoDocumentoId',
      label: 'Tipo de documento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'numeroDocumento',
      label: 'Número de documento',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'telefonoCelular',
      label: 'Teléfono celular',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'telefonoFijo',
      label: 'Teléfono fijo',
      type: 'number',
    },
    {
      name: 'licenseCategoryId',
      label: 'Categoría Licencia',
      type: 'select',
      options: []
    },
    {
      name: 'licenseNumber',
      label: 'Número de licencia',
      type: 'text',
    },
    {
      name: 'direccion',
      label: 'Dirección de domicilio',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'neighborhood',
      label: 'Barrio',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'locality',
      label: 'Localidad',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'stratum',
      label: 'Estrato',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'emergencyContact',
      label: 'Contacto de emergencia',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'emergencyContactNumber',
      label: 'Teléfono de emergencia',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required'
    }
  ],
  nuevaPostulacionEducacionFormal: [
    {
      name: 'nivelAcademicoId',
      label: 'Nivel académico',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'institucionEducativa',
      label: 'Institución educativa',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'tituloObtenido',
      label: 'Título obtenido',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'fechaGraduacion',
      label: 'Fecha de finalización',
      type: 'date',
      validations: 'required',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    },
  ],
  nuevaPostulacionEducacionNoFormal: [
    {
      name: 'nombreDelCurso',
      label: 'Nombre del curso',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'institucionEducativa',
      label: 'Institución educativa',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'endDate',
      label: 'Fecha de finalización',
      type: 'date',
      validations: 'required',
    },
    {
      name: 'horas',
      label: 'Horas de duración',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    },
  ],
  nuevaPostulacionExperienciaProfesional: [

    {
      name: 'nombreEmpresa',
      label: 'Nombre de la empresa',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'mesesLaborados',
      label: 'Meses laborados',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'cargo',
      label: 'Cargo',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'motivoRetiro',
      label: 'Motivo de retiro',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'jefeInmediato',
      label: 'Jefe inmediato',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'telefono',
      label: 'Teléfono',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'fechaInicio',
      label: 'Fecha de inicio',
      type: 'date',
      validations: 'required',
    },
    {
      name: 'fechaFin',
      label: 'Fecha de finalización',
      type: 'date',
      validations: 'required',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    }
  ],
  nuevaPostulacionInformacionFamiliar: [
    {
      name: 'parentescoId',
      label: 'Parentesco',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'nombres',
      label: 'Nombres',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'apellidos',
      label: 'Apellidos',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'fechaNacimiento',
      label: 'Fecha de nacimiento',
      type: 'date',
      validations: 'required',
    },
    {
      name: 'numeroDeIdentificacion',
      label: 'Número de identificación',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'tipoDocumentoId',
      label: 'Tipo de documento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'ocupacion',
      label: 'Ocupación',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    },
  ],
  nuevaPostulacionReferenciasPersonales: [
    {
      name: 'nombresYApellidos',
      label: 'Nombres y apellidos',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'ocupacion',
      label: 'Ocupación',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'telefono',
      label: 'Teléfono',
      type: 'number',
      validations: 'required',
    },
    {
      name: 'direccion',
      label: 'Dirección',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    },
  ],
  nuevaVacante: [
    {
      name: 'nombre',
      label: 'Nombre del cargo',
      type: 'text',
      validations: 'required',
    },
    {
      name: 'tipoCargoId',
      label: 'Nivel de Cargo',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'cargoDependienciaId',
      label: 'Cargo superior',
      type: 'select',
      options: [],
      validations: ''
    },
    {
      name: 'sucursalId',
      label: 'Sucursal',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoIdEmpresa',
      label: 'Departamento empresa',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'areaId',
      label: 'Área',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'fechaDeCierre',
      label: 'Fecha de cierre',
      type: 'date',
      validations: '',
    },
    {
      name: 'salario',
      label: 'Salario',
      type: 'number',
      validations: '',
    },
    {
      name: 'tipoDeContratoId',
      label: 'Tipo de contrato',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'cantidadDeVacantesInicial',
      label: 'Número de vacantes',
      type: 'number',
      validations: '',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'direccion',
      label: 'Dirección',
      type: 'text',
      validations: '',
    },
    {
      name: 'jornadaLaboralId',
      label: 'Jornada laboral',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'descripcion',
      label: 'Descripción',
      type: 'text',
      validations: '',
    }
  ],
  editarVacante: [
    {
      name: 'fechaDeCierre',
      label: 'Fecha de cierre',
      type: 'date',
      validations: '',
    },
    {
      name: 'salario',
      label: 'Salario',
      type: 'number',
      validations: '',
    },
    {
      name: 'tipoDeContratoId',
      label: 'Tipo de contrato',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'cantidadDeVacantesInicial',
      label: 'Número de vacantes',
      type: 'number',
      validations: '',
    },
    {
      name: 'paisId',
      label: 'Pais',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'departamentoId',
      label: 'Departamento',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'municipioId',
      label: 'Municipio',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'direccion',
      label: 'Dirección',
      type: 'text',
      validations: '',
    },
    {
      name: 'jornadaLaboralId',
      label: 'Jornada laboral',
      type: 'select',
      options: [],
      validations: 'required',
    },
    {
      name: 'descripcion',
      label: 'Descripción',
      type: 'text',
      validations: '',
    }
  ],
}



export const reclutamientoYSeleccionActions = {
  async getNuevaPostulacionDatosPersonales({ state, dispatch }) {
    const schema = state.nuevaPostulacionDatosPersonales

    let index = schema.findIndex(item => item.name == 'tipoDocumentoId')
    schema[index].options = await dispatch('getTiposDeDocumentos')

    index = schema.findIndex(item => item.name == 'licenseCategoryId')
    schema[index].options = await dispatch('getCategoriaLicencia')


    index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')

    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },
  async getNuevaPostulacionDatosPersonalesRestringido({ state, dispatch }) {
    const schema = state.nuevaPostulacionDatosPersonales

    let index = schema.findIndex(item => item.name == 'tipoDocumentoId')
    schema[index].options = await dispatch('getTiposDeDocumentos')

    index = schema.findIndex(item => item.name == 'licenseCategoryId')
    schema[index].options = await dispatch('getCategoriaLicencia')

    index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')

    schema[0].state = "disabled"
    schema[1].state = "disabled"
    schema[3].state = "disabled"
    schema[4].state = "disabled"
    schema[5].state = "disabled"

    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },
  async getNuevaPostulacionEducacionFormal({ state, dispatch }) {
    const schema = state.nuevaPostulacionEducacionFormal

    let index = schema.findIndex(item => item.name == 'nivelAcademicoId')
    schema[index].options = await dispatch('getNivelAcademico')

    index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')


    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },

  async getNuevaPostulacionEducacionNoFormal({ state, dispatch }) {
    const schema = state.nuevaPostulacionEducacionNoFormal

    let index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')


    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },

  async getNuevaPostulacionExperienciaProfesional({ state, dispatch }) {
    const schema = state.nuevaPostulacionExperienciaProfesional

    let index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')


    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },

  async getNuevaPostulacionInformacionFamiliar({ state, dispatch }) {
    const schema = state.nuevaPostulacionInformacionFamiliar

    let index = schema.findIndex(item => item.name == 'parentescoId')
    schema[index].options = await dispatch('getParentescos')

    index = schema.findIndex(item => item.name == 'tipoDocumentoId')
    schema[index].options = await dispatch('getTiposDeDocumentos')


    index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')


    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },

  async getNuevaPostulacionReferenciasPersonales({ state, dispatch }) {
    const schema = state.nuevaPostulacionReferenciasPersonales

    let index = schema.findIndex(item => item.name == 'paisId')
    schema[index].options = await dispatch('getPaises')


    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },

  async getNuevaVacante({ state, dispatch }, empresaId) {
    const schema = state.nuevaVacante

    schema[1].options = await dispatch('getNivelDeCargo')
    schema[2].options = await dispatch('getCargosByEmpresa', empresaId)
    schema[3].options = await dispatch('getSucursalesByEmpresa', empresaId)
    schema[8].options = await dispatch('getTiposDeContrato')
    schema[10].options = await dispatch('getPaises')
    schema[14].options = await dispatch('getJornadasLaborales')

    dispatch('getDepartamentosByEmpresa', empresaId)
    dispatch('getAreasByEmpresa', empresaId)

    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },
  async getEditarVacante({ state, dispatch }) {
    const schema = state.editarVacante

    schema[2].options = await dispatch('getTiposDeContrato')
    schema[4].options = await dispatch('getPaises')

    dispatch('getDepartamentos')
    dispatch('getMunicipios')

    return schema
  },
}