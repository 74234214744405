import nuevaPostulacion from "@/views/Helexium/Postulantes/componentesNuevaPostulacion/storeNuevaPostulacion.js";
import schemas from "./modules/schemas.js";
import sourceData from "./modules/sourceData.js";
import lego from "./modules/lego.js";
import terminales from "./modules/terminales.js";
import reportesApex from "./modules/reportesApex.js";
import pesv from "./modules/pesv.js";

export default {
  reportesApex,
  nuevaPostulacion,
  schemas,
  sourceData,
  lego,
  terminales,
  pesv

};
