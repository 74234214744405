<script>

export default {
  props: {
    value: {
      
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: String,
      default: 'label' 
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if(this.value) {
      this.$el.value = this.value !== Object(this.value) ? this.value : this.value[this.optionValue]
    }
  },
  computed: {
    elementIsPrimitive() {
      return this.options[0] !== Object(this.options[0])
    },
    optionsMapped() {
      if( this.elementIsPrimitive ) {
        return this.options.map( option => ({ [this.optionLabel]: option }))
      } else {
        return this.options
      }
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onChange
      }
    }
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.value )
    }
  }
}

</script>

<template>
  <select v-bind="$attrs" v-on="listeners" class="select">
    <option :value="null">{{ placeholder }}</option>
    <option v-for="(option, index) in options" :key="index" :value=" optionValue ? option[optionValue] : option[optionLabel]">
      {{ option[optionLabel] }}
    </option>
  </select>
</template>

<style scoped>
.select {
  display: block;
  width: 100%;
  padding: 12px 10px;
  font-size: 14px;
  border-radius: 8px;
  background-color: #030f3000;
  color: #D5D6D7;
  outline: none;
  border: none;
   box-shadow: 0 0 0 2px #343d93;

  transition: box-shadow .3s;
}

.select:focus {
  /* box-shadow: 0 0 0 2px #232c7c; */
}

body:not(.dark) .select {
  color: #000000;
  background-color: #ebebeb;
  box-shadow: 0 0 0 1px #000000;
}

body:not(.dark) .select:focus {
  color: #000000;

  box-shadow: 0 0 0 1px #19b390;
}

</style>