export default {
  state: {
    // hl_url: "https://bupeapidevelopment.azurewebsites.net/api/",
    // hl_url: "http://devbackend.helexiumfiles.com/api/", //ok
    hl_url: "https://bupewebprodapi.azurewebsites.net/api/",
    // hl_url: "http://localhost:51429/api/",
    // hl_url: "https://localhost:44388/api/", //oknpm r
    //  hl_url: "http://127.0.0.1:44388/api/", //ok
    //hl_url: "https://localhost:44388/api/", //oknpm r
    // hl_url: "http://127.0.0.1:44388/api/", //ok
    // hl_url: "http://192.168.1.2:44388/api/", //ok
    // hl_url: "http://127.0.0.1:44388/Api/", //ok

    // sisi pesv:  QA - entorno de pruebas
    HOST_CORE: "http://179.1.200.179:3333",
    PersonaAct: {},
    sisi_auth: {},
  },
  mutations: {
    setPersonaAct(state, data) {
      state.PersonaAct = data;
      ////console.log(state.PersonaAct.id);
      localStorage.setItem("setPersonaAct", JSON.stringify(state.PersonaAct));
      //  let res = await this.$store.getters.fetchGet( {
      //  path: "Persona/GetPersonaById/" + this.PersonaId,
      //   });
      //  this.Persona = await res.json();
    },
    setSisiAuth(state, data) {
      state.sisi_auth = data;
      localStorage.setItem("sisi_auth", JSON.stringify(state.sisi_auth));
    },
    setData(state, data) {
      state.data = data;
    },
    setToken(state, token) {
      localStorage.setItem("tk", token);
      state.token = token;
    },
  },
  actions: {
    // sisi pesv
    async sisi_auth(context, { data }) {
      try {
        //data = data JSON.stringify({usuario:"999999", contrasena:"Clave78!@"})
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        let res = await fetch(
          context.state.HOST_CORE + "/api/v1/autenticacion/inicio-sesion",
          options
        );
        let result = await res.json();

        this.commit("setSisiAuth", result);

        if (res.error) {
          alert(res.error);
          return;
        } else {
          return result;
        }
      } catch (error) {
        console.error("error", error);
        return {};
      }
    },
    async sisi_get(context, { path }) {
      try {
        let token = JSON.parse(localStorage.getItem("sisi_auth")).token;

        const options = {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        let res = await fetch(context.state.HOST_CORE + path, options);

        let req = await res.json();

        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return req;
        }
      } catch (error) {
        console.error("error", error);
        return error;
      }
    },
    async sisi_post(context, { path, data }) {
      try {
        let token = JSON.parse(localStorage.getItem("sisi_auth")).token;
        const options = {
          method: "post",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.HOST_CORE + path, options);
        let res = await req.json();

        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async sisi_files(context, { path, data }) {
      try {
        let token = JSON.parse(localStorage.getItem("sisi_auth")).token;

        const formData = new FormData();
        formData.append("token", token);
        formData.append("archivo", data.file);
        formData.append("idPregunta", data.idPregunta);
        formData.append("idVigilado", data.idVigilado);

        const options = {
          method: "post",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: formData,
        };

        let req = await fetch(context.state.HOST_CORE + path, options);
        let res = await req.json();

        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async hl_loginPersona(context, { data }) {
      try {
        //data = data JSON.stringify({Usuario:"administrador@helexium.com", contrasena:"Bupe3108/"})
        // data = JSON.stringify({Usuario:"admindemo@testing.com", contrasena:"12345"})
        ////console.log(data)
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: data,
        };
        let res = await fetch(
          context.state.hl_url + "Authentication/LoginPersona",
          options
        );
        let tokenres = await fetch(
          context.state.hl_url + "Authentication/GetToken",
          options
        );
        ////console.log(res)
        let result = await res.json();
        delete result.contrasena;
        delete result.contrasenaReportes;
        delete result.contrasenaIframe;
        tokenres = await tokenres.json();
        this.commit("setToken", tokenres.token);
        this.commit("setPersonaAct", result);
        if (res.error) {
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          alert(res.error);
          return;
        } else {
          return result;
        }
      } catch (error) {
        console.error("error", error);
        return {};
      }
    },
    async hl_login(context, { data }) {
      try {
        //data = data JSON.stringify({Usuario:"administrador@helexium.com", contrasena:"Bupe3108/"})
        // data = JSON.stringify({Usuario:"admindemo@testing.com", contrasena:"12345"})
        ////console.log(data)
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: data,
        };
        let res = await fetch(
          context.state.hl_url + "Authentication/LoginPersonaV1",
          options
        );
        let tokenres = await fetch(
          context.state.hl_url + "Authentication/GetTokenV1",
          options
        );
        ////console.log(res)
        let result = await res.json();
        delete result.contrasena;
        delete result.contrasenaReportes;
        delete result.contrasenaIframe;
        tokenres = await tokenres.json();
        this.commit("setToken", tokenres.token);
        this.commit("setPersonaAct", result);
        if (res.error) {
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          alert(res.error);
          return;
        } else {
          return result;
        }
      } catch (error) {
        console.error("error", error);
        return {};
      }
    },
    async hl_get(context, { path }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        let res = await fetch(context.state.hl_url + path, options);
        //console.log("get", res);
        let req = await res.json(); // await JSON.parse(res)
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          console.error(res.error);
          alert(res.mensaje);
        } else {
          return req;
        }
      } catch (error) {
        console.error("error", error);
        return error;
      }
    },
    async hl_post(context, { path, data }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "post",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
    async hl_delete(context, { path, data }) {
      try {
        let token = localStorage.getItem("tk");
        const options = {
          method: "delete",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        };
        let req = await fetch(context.state.hl_url + path, options);
        let res = await req.json();
        if (res.mensaje == "Token inválida") {
          //router.push('/')
        }
        if (res.error) {
          alert(res.error);
        } else {
          return res;
        }
      } catch (error) {
        console.error("error---------->", error);
        return error;
      }
    },
  },
  getters: {},
};
