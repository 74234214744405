export default {
    state: {
        persona: {
            nombres: null,
            apellidos: null,
            tipoDocumentoId: null,
            numeroDocumento: null,
            telefonoCelular: null,
            telefonoFijo: null,
            licenseCategoryId: null,
            licenseNumber: null,
            direccion: null,
            neighborhood: null,
            locality: null,
            stratum: null,
            emergencyContact: null,
            emergencyContactNumber: null,
            vacanteId: null,
            correo: null,
            dateBorn: null,
            paisId: null,
            municipioId: null,
            departamentoId: null,
        },
        paises: [],
        departamentos: [],
        municipios: [],
        tiposDeDocumento: [],
        tiposDeLicenciaDeConduccion: [],
        nivelesAcademicos: [],
        parentescos: [],
        resumenFormacion: [],
        resumenFormacionNoFormal: [],
        resumenExperiencia: [],
        resumenInformacionFamiliar: [],
        resumenReferencias: [],
        postulacionId: null,


    },
    mutations: {
        setStateByKey( state, { key, value }) {
            state[key] = value;
        },
        appendElement( state, { key, newElement}) {
            state[key].push({...newElement})
        },
        deleteElementAt( state, { key, idx }) {
            state[key].splice(idx, 1)
        },
        setVacanteId(state, vacanteId) {
            state.persona.vacanteId = vacanteId
        }
    },
    actions: {
        fetchData({ commit, getters}, { keyState, path, id = null }) {
            getters.fetchGet({ path: id ? `${path}/${id}` : path })
                .then(response => {
                    if(response.ok){
                        return response.json()
                    }
                    throw new Error(response.status)
                })
                .then( data => {
                    commit('setStateByKey', { key: keyState, value: data })
                })
                .catch(error => console.error(error))
        },

        setPaises({ dispatch }) {
            dispatch('fetchData', { 
                keyState: 'paises', 
                path: 'Pais/all' 
            })
        },
        setDepartamentos({ dispatch }, paisId) {
            dispatch('fetchData', { 
                keyState: 'departamentos', 
                path: 'DepartamentoPais/ListByPais', 
                id: paisId 
            })
        },
        setMunicipios({ dispatch }, departamentoId) {
            dispatch('fetchData', { 
                keyState: 'municipios', 
                path: 'Municipio/ListByDepartamento', 
                id: departamentoId 
            })
        },
        setTiposDeDocumentos({dispatch}) {
            dispatch('fetchData', {
                keyState: 'tiposDeDocumento', 
                path: 'TipoDocumento/TiposDeDocumentos'
            })
        },
        setTiposDeLicenciaDeConduccion({dispatch}) {
            dispatch('fetchData', { 
                keyState: 'tiposDeLicenciaDeConduccion', 
                path: 'ValuesCatalog/GetListByCodeCatalog/CategoriaLicencia'
            })
        },
        setNivelesAcademicos({dispatch}) {
            dispatch('fetchData', { 
                keyState: 'nivelesAcademicos', 
                path: 'ValuesCatalog/GetListByCodeCatalog/NivelesAcademicos'
            })
        },
        setParentescos({dispatch}) {
            dispatch('fetchData', {
                keyState: 'parentescos',
                path: 'ValuesCatalog/GetListByCodeCatalog/Parentesco'
            })
        },
        setInitData({dispatch}) {
            dispatch('setPaises');
            dispatch('setTiposDeDocumentos');
            dispatch('setTiposDeLicenciaDeConduccion');
            dispatch('setNivelesAcademicos');
            dispatch('setParentescos');
        }

    },
    getters: {
        getPaises(state) {
            return state.paises;
        },
        getDepartamentos(state) {
            return state.departamentos;
        },
        getMunicipios(state) {
            return state.municipios;
        },
        getTiposDeDocumentos(state) {
            return state.tiposDeDocumento;
        },
        getTiposDeLicenciaDeConduccion(state) {
            return state.tiposDeLicenciaDeConduccion;
        },
        getNivelesAcademicos(state) {
            return state.nivelesAcademicos;
        },
        getParentescos(state) {
            return state.parentescos;
        },
        resumenExperiencia(state) {
            return state.resumenExperiencia
        },
        resumenFormacion(state) {
            return state.resumenFormacion
        },
        resumenFormacionNoFormal(state) {
            return state.resumenFormacionNoFormal
        },
        resumenInformacionFamiliar(state) {
            return state.resumenInformacionFamiliar
        },
        resumenReferencias(state) {
            return state.resumenReferencias
        },
        datosPersonales(state) {
            return state.persona
        },
        postulacionId(state) {
            return state.postulacionId
        }

    }
}